<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :is-page-show="true"
      api-url="/license/v1/list"
      table-height="calc(100vh - 215px)"
      @showDialogCreate="showDialogCreate"
      @showDialogEdit="showDialogEdit"
      @showDialogPermission="showDialogPermission"
    ></custom-table>

    <v-dialog v-model="createDialog" persistent max-width="640px">
      <PriceCreate
        v-if="createDialog"
        :item-detail="itemDetail"
        @cancel="hideDialogCreate"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="permissionDialog" persistent max-width="1200px">
      <PricePermission
        v-if="permissionDialog"
        :item-detail="itemDetail"
        @cancel="hideDialogPermission"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  LICENSE_PRICE_STATUS_OPTIONS,
  LICENSE_PRICE_UNIT_OPTIONS,
  LICENSE_PRICE_TYPE_OPTIONS,
  LICENSE_PRICE_CLASSIFY_OPTIONS,
  VAT_OPTIONS,
} from "@/libs/const";
export default {
  components: {
    PriceCreate: () => import("@/components/license/PriceCreate"),
    PricePermission: () => import("@/components/license/PricePermission"),
  },
  name: "Price",
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    createDialog: false,
    editDialog: false,
    permissionDialog: false,
    priceDialog: false,
    itemDetail: {},
    statusOptions: [...LICENSE_PRICE_STATUS_OPTIONS],
    typeOptions: [...LICENSE_PRICE_TYPE_OPTIONS],
    classifyOptions: [...LICENSE_PRICE_CLASSIFY_OPTIONS],
    unitOptions: [...LICENSE_PRICE_UNIT_OPTIONS],
    vatOptions: [...VAT_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.date_create"),
        placeholder: this.$t("labels.date_create"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.identity_create"),
        placeholder: this.$t("labels.identity_create"),
        name: "identity_name",
        hasSort: false,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "select-country",
        label: this.$t("labels.country"),
        placeholder: this.$t("labels.country"),
        name: "id_country",
        hasSort: false,
        sortName: "country_name",
        key: "country_name",
        required: true,
        class: "c-input-small",
      },
      {
        type: "select-filter",
        label: this.$t("labels.classify"),
        placeholder: this.$t("labels.classify"),
        name: "classify",
        hasSort: false,
        sortName: "classify",
        key: "classify",
        options: this.classifyOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.administer"),
        placeholder: this.$t("labels.administer"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: this.typeOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.function"),
        placeholder: this.$t("labels.function"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.unit"),
        placeholder: this.$t("labels.unit"),
        name: "unit",
        hasSort: false,
        sortName: "unit",
        key: "unit",
        options: this.unitOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.max_order_per_month"),
        placeholder: this.$t("labels.max_order_per_month"),
        name: "max_order_per_month",
        hasSort: false,
        sortName: "max_order_per_month",
        key: "max_order_per_month",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.max_account_per_warehouse"),
        placeholder: this.$t("labels.max_account_per_warehouse"),
        name: "max_account_per_warehouse",
        hasSort: false,
        sortName: "max_account_per_warehouse",
        key: "max_account_per_warehouse",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.price"),
        placeholder: this.$t("labels.price"),
        name: "price",
        hasSort: false,
        sortName: "price",
        key: "price",
        required: true,
        /* actionPermissions: [],
        actionModules: [],
        action: "showDetailDialog", */
      },
      {
        type: "select-filter",
        label: this.$t("labels.vat"),
        placeholder: this.$t("labels.vat"),
        name: "vat",
        hasSort: false,
        sortName: "vat",
        key: "vat",
        options: this.vatOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        required: true,
        buttonActions: [
          {
            action: "showDialogEdit",
            text: this.$t("labels.edit"),
            color: "warning",
            class: "mb-1 v-btn--block",
            permissions: [],
            modules: [],
          },
          {
            action: "showDialogPermission",
            text: this.$t("labels.permission"),
            color: "success",
            class: "mb-1 v-btn--block",
            permissions: [],
            modules: [],
          },
          /* {
            action: "showDialogPrice",
            text: this.$t("labels.price_history"),
            color: "purple",
            class: "white--text v-btn--block",
            permissions: [],
            modules: [],
          }, */
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.itemDetail = {};
      this.createDialog = false;
    },
    showDialogEdit(item) {
      this.itemDetail = { ...item };
      this.showDialogCreate();
    },
    showDialogPermission(item) {
      this.itemDetail = { ...item };
      this.permissionDialog = true;
    },
    hideDialogPermission() {
      this.itemDetail = {};
      this.permissionDialog = false;
    },
    showDialogPrice(item) {
      this.itemDetail = { ...item };
      this.priceDialog = true;
    },
    hideDialogPrice() {
      this.itemDetail = {};
      this.priceDialog = false;
    },
  },
};
</script>
